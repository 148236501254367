import React from 'react';

import { Page } from 'app/core/components/Page/Page';

export function BannerSettingsPage() {
  return (
    <Page navId="banner-settings">
      <Page.Contents>
        <div>Banner settings page</div>
      </Page.Contents>
    </Page>
  );
}
